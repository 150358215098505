var PartialQueue: Function[] | null;

export interface IRequestOptions
{
    url: string;
    callback?: (state: UpdateState) => void;
    content?: FormData;
}

export class UpdateState
{
    private readonly url: string;
    private readonly callback: ((state: UpdateState) => void) | undefined;
    private readonly content: FormData | undefined;
    private readonly xhr: XMLHttpRequest;

    public constructor(params: IRequestOptions)
    {
        this.url = params.url;
        this.callback = params.callback;
        this.content = params.content;
        this.xhr = new XMLHttpRequest();
        this.xhr.onreadystatechange = () => this.stateChange();
    }

    public abort()
    {
        if (this.xhr.readyState !== XMLHttpRequest.DONE)
        {
            this.xhr.abort();
        }
    }

    public update()
    {
        const method = this.content != null ? "POST" : "GET";
        this.xhr.open(method, this.url, true);
        this.xhr.send(this.content);
    }

    public stateChange()
    {
        if (this.xhr.readyState === XMLHttpRequest.DONE && this.xhr.status === 200)
        {
            if (typeof (this.callback) !== "undefined")
            {
                this.callback(this);
            }
        }
    }

    public getResponse()
    {
        return this.xhr.responseText;
    }
}

export function RequestView(params: IRequestOptions)
{
    const state = new UpdateState(params);
    state.update();
}

function updateViewContent(state: UpdateState, targetElement: HTMLElement)
{
    targetElement.innerHTML = state.getResponse();
    const scripts = targetElement.querySelectorAll("script");
    for (let i = 0; i < scripts.length; i++)
    {
        const scriptElem = scripts[i];
        const scriptInsert = document.createElement("script");
        if (scriptElem.src !== "")
        {
            scriptInsert.src = scripts[i].src;
        }
        else
        {
            scriptInsert.appendChild(document.createTextNode(scripts[i].innerText.trim()));
        }
        scripts[i].remove();
        targetElement.appendChild(scriptInsert);
    }

    const forms = targetElement.querySelectorAll("form");
    for (let i = 0; i < forms.length; i++)
    {
        const form = forms[i];
        $.validator.unobtrusive.parse(form);
        $(form).validate().settings.submitHandler = () =>
        {
            const state = new UpdateState({
                url: form.action,
                content: new FormData(form),
                callback: (stateObj: UpdateState) => updateViewContent(stateObj, form.parentElement as HTMLElement)
            });
            state.update();
        };
    }

    RunQueue();
}

export function RunQueue()
{
    PartialQueue = PartialQueue || [];
    while (PartialQueue.length !== 0)
    {
        const item = PartialQueue.pop();
        if (item != null)
        {
            item();
        }
    }
}

export function InitialiseForElement(form: HTMLFormElement)
{
    $(form).validate().destroy();
    $.validator.unobtrusive.parse(form);


    $(form).validate().settings.submitHandler = () =>
    {
        const state = new UpdateState({
            url: form.action,
            content: new FormData(form),
            callback: (stateObj: UpdateState) => updateViewContent(stateObj, form.parentElement as HTMLElement)
        });
        state.update();
    };
}

export function SubmitElement(form: HTMLFormElement)
{
    const state = new UpdateState({
        url: form.action,
        content: new FormData(form),
        callback: (stateObj: UpdateState) => updateViewContent(stateObj, form.parentElement as HTMLElement)
    });
    state.update();
}

window.addEventListener("load", RunQueue);