
import { SubmitElement } from './PartialHost';

class RecaptchaForm
{
    private readonly rootElement: HTMLFormElement | null = null;

    private recaptchaCallback(token: string)
    {
        if (!this.rootElement || !token)
        {
            return;
        }

        const tokenElem = document.createElement("input");
        tokenElem.type = "hidden";
        tokenElem.name = "recaptcha_token";
        tokenElem.value = token;
        this.rootElement.append(tokenElem);

        SubmitElement(this.rootElement);
    }

    public constructor(rootElem: HTMLFormElement)
    {
        this.rootElement = rootElem;
        if (!this.rootElement)
        {
            return;
        }

        const siteKey = this.rootElement.dataset.recaptcha;
        if (!siteKey)
        {
            return;
        }

        const action = this.rootElement.dataset.recaptchaAction ?? "form_submit";

        $(this.rootElement).validate().destroy();
        $.validator.unobtrusive.parse($(this.rootElement as HTMLElement));

        $(this.rootElement).validate().settings.submitHandler = () =>
        {
            grecaptcha.ready(() =>
            {
                grecaptcha.execute(siteKey, { action: action }).then((token) => this.recaptchaCallback(token));
            });
        };
    }
}

export function Initialise(rootElem: HTMLFormElement)
{
    return new RecaptchaForm(rootElem);
}