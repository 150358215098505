import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'jssocials';

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import '../../../Components/Base/BaseComponent/Content/Components/StyleSwitcher.ts';

import '../../../Components/Base/BaseComponent/Content/components/CKReadMore';
import '../../../Components/Base/BaseComponent/Content/components/MapOffices';
import '../../../Components/Base/BaseComponent/Content/components/AjaxPage';

import { InitialiseForElement as AjaxFormInitialise } from '../../../Components/Base/BaseComponent/Content/Components/PartialHost';
import { Initialise as RecaptchaFormInitialise } from '../../../Components/Base/BaseComponent/Content/Components/RecaptchaForm';
import { Initialize as InitializeGallerySliders } from './components/GallerySlider';

document.body.classList.remove("no-js");
document.body.classList.add("js");

$(function ()
{
    const forms = document.querySelectorAll("form[data-ajax]");
    for (let i = 0; i < forms.length; i++)
    {
        if ((forms[i] as HTMLFormElement).dataset.recaptcha)
        {
            RecaptchaFormInitialise(forms[i] as HTMLFormElement);
        }
        else
        {
            AjaxFormInitialise(forms[i] as HTMLFormElement);
        }
    }

    $("[data-socials]").each(function ()
    {
        $(this).jsSocials({
            showLabel: false,
            showCount: false,
            url: $(this).data("url"),
            shares: ["facebook", "twitter", "linkedin", "email"]
        });
    });

    InitializeGallerySliders();
});