const documentSliders: GallerySlider[] = [];

class GallerySlider
{
    private container: HTMLElement;

    constructor(container: HTMLElement)
    {
        this.container = container;

        const newNodes: Node[] = [];
        for (let i = 0; i < this.container.childNodes.length; i++)
        {
            const child = this.container.childNodes[i];

            if (child.nodeType == Node.ELEMENT_NODE)
            {
                newNodes.push(child.cloneNode(true));
            }
            else
            {
                child.remove();
            }
        }

        setInterval(() => this.advanceSlider(), 8000);
    }

    private advanceSlider()
    {
        this.container.classList.add("tick");

        setTimeout(() => this.cleanSlider(), 500);
    }

    private cleanSlider()
    {
        const first = this.container.childNodes.item(0);
        this.container.appendChild(first);

        this.container.classList.remove("tick");
    }
}

export function Initialize()
{
    const sliders = document.querySelectorAll("div.gallery-slider");

    for (let i = 0; i < sliders.length; i++)
    {
        documentSliders.push(new GallerySlider(sliders[i] as HTMLElement));
    }
}