
class AjaxPage
{
    private readonly parent: HTMLElement;
    private readonly loading: HTMLDivElement;
    private readonly xhr: XMLHttpRequest;
    private readonly ajaxUrl: string | null;
    private readonly ajaxPageInput: HTMLInputElement | null;
    private readonly formData: FormData | null;

    private currentPage: number = 1;
    private totalPages: number;

    private loadingMore: boolean = false;
    private scrollWait: number | null = null;

    private autoResponse()
    {
        const pageElem = document.createElement("div");
        pageElem.dataset.page = this.currentPage.toFixed(0);
        pageElem.innerHTML = this.xhr.responseText;

        this.currentPage++;
        this.parent.insertBefore(pageElem.firstElementChild as Element, this.loading);
    }

    private stateChange()
    {
        if (this.xhr.readyState === XMLHttpRequest.DONE)
        {
            this.loadingMore = false;
            this.scrollWait = null;

            this.loading.classList.remove("show");
            if (this.xhr.status === 200)
            {
                this.autoResponse();
            }
        }
    }

    private hasMorePages()
    {
        return this.currentPage < this.totalPages;
    }

    public fetchNextPageUrl()
    {
        if (this.ajaxUrl == null || !this.hasMorePages())
        {
            return;
        }

        this.loading.classList.add("show");
        const url = this.ajaxUrl.replace("2", (this.currentPage + 1).toFixed(0))
        this.xhr.open("GET", url, true);
        this.xhr.setRequestHeader("X-Format-Requested", "AjaxPage");
        this.xhr.send();
    }

    public fetchNextPageForm()
    {
        if (this.ajaxPageInput == null || this.formData == null || this.ajaxPageInput.form == null || !this.hasMorePages())
        {
            return;
        }

        this.formData.set(this.ajaxPageInput.name, this.currentPage.toFixed(0));

        this.loading.classList.add("show");
        const urlParams = new URLSearchParams(this.formData as any);
        this.xhr.open("GET", `${this.ajaxPageInput.form.action}?${urlParams.toString()}`);
        this.xhr.setRequestHeader("X-Format-Requested", "AjaxPage");
        this.xhr.send(this.formData);
    }

    private testScrollEnd()
    {
        const parentPosition = this.parent.getBoundingClientRect();
        const bottom = (parentPosition.y + parentPosition.height);
        if (window.scrollY > bottom && bottom > 0)
        {
            this.loadingMore = true;
            if (this.ajaxUrl)
            {
                this.fetchNextPageUrl();
            }
            else if (this.ajaxPageInput)
            {
                this.fetchNextPageForm();
            }
        }
    }

    private testScroll()
    {
        if (this.loadingMore)
        {
            return;
        }

        if (this.scrollWait)
        {
            clearTimeout(this.scrollWait);
        }

        this.scrollWait = setTimeout((() => this.testScrollEnd()) as TimerHandler, 50);
    }

    public static create(parentElem: HTMLElement): AjaxPage | null
    {
        const pagesUrl = parentElem.dataset.pagesUrl;
        const pagesForm = parentElem.dataset.pagesForm;
        const pagesCount = parentElem.dataset.pagesCount;

        if (pagesCount == null || pagesCount === "")
        {
            return null;
        }

        if (pagesForm != null && pagesForm !== "")
        {
            const form = document.getElementById(pagesForm);

            if (form == null || form.tagName !== "INPUT" || (form as HTMLInputElement).form == null)
            {
                return null;
            }

            return new AjaxPage(parentElem, form as HTMLInputElement, parseInt(pagesCount));
        }

        if (pagesUrl != null && pagesUrl !== "")
        {
            return new AjaxPage(parentElem, pagesUrl, parseInt(pagesCount));
        }

        return null;
    }

    private constructor(parentElem: HTMLElement, ajaxTarget: HTMLInputElement | string, pageCount: number)
    {
        this.parent = parentElem;
        this.totalPages = pageCount;
        this.xhr = new XMLHttpRequest();
        this.xhr.onreadystatechange = () => this.stateChange();

        if (typeof ajaxTarget === "string")
        {
            this.ajaxUrl = ajaxTarget;
            this.ajaxPageInput = null;
            this.formData = null;
        }
        else if (typeof ajaxTarget === "object")
        {
            this.ajaxUrl = null;
            this.ajaxPageInput = ajaxTarget;

            if (this.ajaxPageInput != null && this.ajaxPageInput.form != null)
            {
                this.formData = new FormData(this.ajaxPageInput.form);
            }
            else
            {
                this.formData = null;
            }
        }
        else
        {
            this.ajaxUrl = null;
            this.ajaxPageInput = null;
            this.formData = null;
        }

        this.loading = document.createElement("div");
        this.loading.className = "loading collapse";
        this.loading.innerHTML = "<i class=\"las la-spinner la-spin\"></i> Loading...";
        this.parent.appendChild(this.loading);

        window.addEventListener("scroll", () => this.testScroll(), { passive: true });
    }
}

const autoElements = document.querySelectorAll("[data-pages]");
for (let i = 0; i < autoElements.length; i++)
{
    AjaxPage.create(autoElements[i] as HTMLElement);
}