
function ToggleReadMore(ev: Event)
{
    ev.preventDefault();

    if (!(ev.currentTarget instanceof HTMLButtonElement))
    {
        return;
    }

    if (ev.currentTarget.parentElement == null)
    {
        return;
    }

    ev.currentTarget.parentElement.classList.toggle("open");
}

const elems = document.querySelectorAll(".ck-rm-wrapper > button.ck-rm-button");
for (let i = 0; i < elems.length; i++)
{
    elems[i].addEventListener("click", ToggleReadMore);
}